<template>
  <div id="sponsors" class="wrapper footer" :style="{ backgroundImage: 'url(' + image + ')' }" >

    <div class="container">
      <h2>Partners &amp; donors</h2>

      <div class="brands">
        <transition-group name="list" tag="ul" class="brand hosts" mode="out-in">
          <li v-for="(item, index) in hosts" :key="index" class="item" :class="item.class" >
            <a :href="item.url" :title="item.name" target="_blank">
                <img :src="require(`@/assets/img/company-logo/${item.logo}`)" :alt="item.name">
            </a>
          </li>
        </transition-group>

        <!-- <transition-group name="list" tag="ul" class="brand partners" mode="out-in">
          <li class="item" v-for="(item, index) in partners" :key="index">
            <a :href="item.url" :title="item.name" target="_blank">
                <img :src="require(`@/assets/img/company-logo/${item.logo}`)" :alt="item.name">
            </a>
          </li>
        </transition-group> -->
      </div>

      <transition-group name="list" tag="ul" class="individuals" mode="out-in">
        <li v-for="(item, index) in individuals" :key="index" class="item">{{ item }}</li>
      </transition-group>

    </div>


  </div>

</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      image: require('@/assets/img/background.jpg'),
      hosts: [
        { logo: 'vietswiss.svg', name: 'VietSwiss', url: 'https://vietswiss.com', class: ''},
        { logo: 'viclaw.svg', name: 'VicLaw', url: 'http://www.viclaw.com.vn/en', class: ''},
        { logo: 'timvest.svg', name: 'Timvest', url: 'https://timvest.ch', class: ''},
        { logo: 'oertli.svg', name: 'Oertli', url: 'https://www.oertli-instruments.com', class: ''},
        { logo: 'absolut.svg', name: 'Absolut Agentur', url: 'https://www.absolutagentur.ch/agentur/', class: ''},
        { logo: 'bamboo.svg', name: 'Bamboo Delivery', url: 'https://bamboo.ch/', class: ''},
        { logo: 'aforce.svg', name: 'AForce AG', url: 'http://www.aforceag.ch/', class: ''},
        { logo: 'kliqs.svg', name: 'Kliqs Digital', url: 'https://kliqs.ch/', class: 'kliqs'},
        { logo: 'vmt.svg', name: 'VMT Solutions', url: 'https://vmts.ch/', class: 'vmt'},
        { logo: 'rolf.svg', name: 'Rolf Schubiger', url: 'https://www.rolf-schubiger.ch/home/', class: ''},
        { logo: 'neusa.svg', name: 'Neusa', url: 'https://www.neusa.ch/', class: ''},
        { logo: 'caspar.svg', name: 'Caspar Eberhard - Werkstatt für Digitales', url: 'https://www.caspar-eberhard.ch/', class: ''},
        { logo: 'aretis.svg', name: 'Aretis - Moderne KMU-Webseiten', url: 'https://www.aretis.ch/', class: ''},
        { logo: 'hoferschmid.svg', name: 'HOFERSCHMID - Agentur für Strategie, Kreation und Kommunikation.', url: 'https://hoferschmid.ch/', class: ''},

      ],
      individuals: [
        'Sebastian Ochsner', 'Lilit Zakharyan', 'Kurt Brunnschweiler', 'Thomas / Tamara Bosshard', 'Werner Und Ruth Vetsch', 'Rolf Schubiger Vertriebs AG', 'Reto Grubenmann / Rutishauser Weinkulturen', 'Ralph Zürcher','Ernst / Susann Kraft', 'Marco Martinelli', 'Anni Kraft', 'Roman Zollet', 'Nicole / Robert Schneider','Andreas Pfister', 'Jan Villa', 'Steffen Tolle', 'Danny Leev', 'Oliver Kraft', 'Adrian Bieri', 'Genta Deva'
      ],
      response: null

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


  .wrapper {
    padding: 30px 0px 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
      color: #fff;
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
    }

    .brand {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        padding: 20px;
        display: flex;
        align-items: center;

        a {
          display: block;
        }

        img {
          width: 100%;
          display: block;
        }

        &.kliqs,
        &.vmt {
          a {
            width: 40px;
            margin: 0 auto;
          }
        }
      }
    }


    .individuals {
      margin: 40px 0;
      padding-left: 20px;

      li {
          color: #fff;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .wrapper {
      padding: 60px 20px;

      .brand {
        margin-top: 50px;
        justify-content: flex-start;
        align-items: center;

        li {
          width: 33.33333%;
          padding: 25px 30px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .wrapper {
      // padding: 60px 20px;

      // .brand {
      //   margin-top: 50px;
      //   justify-content: flex-start;
      //   align-items: center;

      //   li {
      //     width: 33.33333%;
      //     padding: 20px 20px;
      //   }
      // }

      .individuals {
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;

        li {
          width: 33.33%;
          padding-right: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .wrapper {

      h2 {
        font-size: 60px;
      }

      .brand {
        li {
          width: 20%;
          // padding: 25px 30px;

          &.kliqs,
          &.vmt {
            a {
              width: 60px;
            }
          }
        }
      }
    }
  }


</style>
